<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <h2>Test config</h2>
      <router-link :to="'/tests/' + $route.params.id">
        <v-btn
            color="primary"
        >
          Edit test
        </v-btn>
      </router-link>
    </div>

    <div style="margin: 10px 0">
          <v-expansion-panels>
            <draggable v-model="tQuestions" style="width: 100%">
              <transition-group type="transition" name="flip-list">
                <v-expansion-panel
                    v-for="(question, qIndex) in tQuestions"
                    :key="qIndex"
                >
                  <v-expansion-panel-header>
                    <div class="spoiler-header">
                      <v-text-field
                          v-model="question.name"
                          :rules="requiredRules"
                          label="Name"
                          @click.stop.prevent=""
                          required
                          v-if="editQ === qIndex"
                          style="max-width: 400px"
                      ></v-text-field>
                      <div v-if="editQ !== qIndex">{{ question.name }}</div>
                      <div class="spoiler-btns">
                        <v-btn
                            depressed
                            color="success"
                            class="mr-2"
                            @click.stop="saveQuestion"
                            v-if="editQ === qIndex"
                        >
                          Save
                        </v-btn>
                        <v-btn
                            depressed
                            color="primary"
                            class="mr-2"
                            @click.stop="editQuestion(qIndex)"
                            v-if="editQ !== qIndex"
                        >
                          Edit
                        </v-btn>
                        <v-btn
                            depressed
                            color="error"
                            class="mr-2"
                            @click.stop="deleteQuestion(qIndex)"
                        >
                          Delete
                        </v-btn>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels>
                      <draggable v-model="question.answers" style="width: 100%">
                        <transition-group type="transition" name="flip-list">
                          <div :key="qIndex">
                            <v-file-input
                                show-size
                                label="Image"
                                truncate-length="30"
                                @change="onFileChange"
                                v-if="editQ === qIndex"
                            ></v-file-input>
                            <img
                                v-if="previews[qIndex]"
                                :src="previews[qIndex]"
                                alt=""
                                style="max-width: 100%;width: 250px; object-fit: cover;margin-bottom: 10px"
                            />
                          </div>
                          <v-expansion-panel
                              v-for="(answer, aIndex) in question.answers"
                              :key="qIndex + 'a1' + aIndex"
                          >
                            <v-expansion-panel-header>
                              <v-text-field
                                  v-model="answer.name"
                                  :rules="requiredRules"
                                  label="Name"
                                  @click.stop.prevent=""
                                  required
                                  v-if="editQ === qIndex"
                              ></v-text-field>
                              <div v-if="editQ !== qIndex">{{ answer.name }}</div>

                              <div class="spoiler-btns">
                                <div>Correct answer</div>
                                <v-checkbox
                                    v-model="answer.valid"
                                    color="success"
                                    class="spoiler-checkbox"
                                    hide-details
                                    @click.stop="selectValidAnswer(qIndex, aIndex)"
                                    :disabled="editQ !== qIndex"
                                ></v-checkbox>
                                <v-btn
                                    depressed
                                    color="error"
                                    class="mr-2"
                                    @click.stop="deleteAnswer(qIndex, aIndex)"
                                >
                                  Delete
                                </v-btn>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-textarea
                                  label="Description"
                                  :rules="requiredRules"
                                  v-model="answer.description"
                                  @click.stop.prevent=""
                                  required
                                  v-if="editQ === qIndex"
                              ></v-textarea>
                              <div v-if="editQ !== qIndex">
                                {{ answer.description }}
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </transition-group>
                      </draggable>
                    </v-expansion-panels>
                    <div class="add-btn-block" v-if="editQ === qIndex">
                      <v-btn
                          depressed
                          color="primary"
                          class="add-btn"
                          @click="addAnswer(qIndex)"
                      >
                        Add answer
                      </v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </transition-group>
            </draggable>
          </v-expansion-panels>

      <div class="add-btn-block">
        <v-btn
            depressed
            color="primary"
            class="add-btn"
            @click="addQuestion"
        >
          Add question
        </v-btn>
      </div>

    </div>

    <v-btn
        :disabled="!valid"
        :loading="isLoading"
        color="success"
        class="mr-4"
        @click="submit"
    >
      Submit
    </v-btn>
    <AreYouSure v-if="deleteQIndex || deleteQIndex === 0" v-on:closeModal="closeModal" v-on:submitModal="submitModal" />
  </div>
</template>


<script>
  import vSelect from 'vue-select'
  import draggable from 'vuedraggable'
  import AreYouSure from "../../components/modal/AreYouSure";

  export default {
    name: 'TestConfig',
    components: {
      vSelect,
      draggable,
      AreYouSure
    },
    data() {
      return {
        isLoading: false,
        valid: true,
        categories: [],
        questions: [],
        answers: [],
        name: '',
        category: '',
        tQuestions: [],
        editQ: null,
        requiredRules: [
          v => !!v || 'Field is required',
        ],
        deleteQIndex: null,
        deleteAIndex: null,
        previews: {}
      }
    },
    mounted() {
      this.getCategories();
      this.getAnswers();
      this.getTest();
    },
    methods: {
      submit() {
        this.isLoading = true;
        let id = this.$route.params.id;
        let url = '/tests/' + id + '/config'

        let questions = [];
        for (let k in this.tQuestions) {
          let answers = [];

          for (let k2 in this.tQuestions[k].answers) {
            if (this.tQuestions[k].answers[k2].name) {
              let a = {
                name: this.tQuestions[k].answers[k2].name,
                description: this.tQuestions[k].answers[k2].description,
                index: Number(k2),
                valid: !!this.tQuestions[k].answers[k2].valid
              };
              if (this.tQuestions[k].answers[k2]._id) {
                a._id = this.tQuestions[k].answers[k2]._id;
              }
              answers.push(a)
            }
          }

          if (this.tQuestions[k].name) {
            let q = {
              name: this.tQuestions[k].name,
              index: Number(k),
              answers: answers,
              image: this.tQuestions[k].image
            };
            if (this.tQuestions[k]._id) {
              q._id = this.tQuestions[k]._id;
            }
            questions.push(q);
          }
        }

        let data = {
          questions: questions
        }

        this.$axios.post(url, data).then((res) => {
          this.$root.notify({color: 'success', text: 'Success updated'})

          this.$router.push("/tests")
        }).catch(err => {
          this.isLoading = false;
          this.$root.notify({color: 'error', text: err.error ? err.error : "Unknown Error!"})
        });
      },
      onFileChange(payload) {
        const file = payload;
        this.tQuestions[this.editQ].image = file;
        if (file) {
          // this.previews[this.editQ] = URL.createObjectURL(file);
          if (this.previews[this.editQ]) {
            delete this.previews[this.editQ];
          }
          this.$set(this.previews, this.editQ, URL.createObjectURL(file));
          URL.revokeObjectURL(file);
          this.uploadFile(file)
        } else {
          this.previews[this.editQ] = null
        }
      },
      uploadFile(f) {
        let formData = new FormData();
        formData.append("files", f);

        return this.$axios.post('/' + this.$store.state.filesUrl, formData).then(res => {
          this.tQuestions[this.editQ].image = res.data[0]._id;
        }).catch(err => {
          this.$root.notify({color: 'error', text: `${f.name} upload failed`})
        })
      },
      addAnswer(qIndex) {
        let c = (Number(this.tQuestions[qIndex].answers.length) + 1);
        let newQ = {
          name: 'New answer #' + c,
          description: 'New answer desc #' + c,
        }
        this.tQuestions[qIndex].answers.push(newQ);
      },
      addQuestion() {
        let newQ = {
          name: 'New question #' + (Number(this.tQuestions.length) + 1),
          answers: []
        }
        this.tQuestions.push(newQ);
      },
      editQuestion(qIndex) {
        this.editQ = qIndex;
      },
      saveQuestion() {
        this.editQ = null;
      },
      deleteQuestion(qIndex) {
        this.deleteQIndex = qIndex;
      },
      deleteAnswer(qIndex, aIndex) {
        this.deleteQIndex = qIndex;
        this.deleteAIndex = aIndex;
      },
      submitModal() {
        if (this.deleteAIndex || this.deleteAIndex === 0) {
          this.tQuestions[this.deleteQIndex].answers.splice(this.deleteAIndex, 1);
        } else {
          this.tQuestions.splice(this.deleteQIndex, 1);
          this.deleteAIndex = null;
        }
        this.deleteQIndex = null;
      },
      closeModal() {
        this.deleteQIndex = null;
        this.deleteAIndex = null;
      },
      selectValidAnswer(qIndex, aIndex) {
        let answers = this.tQuestions[qIndex].answers;
        for (let k in answers) {
          if (k != aIndex) {
            answers[k].valid = false;
          }
        }
        this.tQuestions[qIndex].answers = answers
      },
      getTest() {
        let id = this.$route.params.id;
        let url = '/tests/' + id + '/full';
        this.$axios.get(url).then(res => {
          this.name = res.data.name;
          this.category = res.data.category && res.data.category._id ? res.data.category._id : res.data.category;
          let questions = res.data.questions.map(item => {
            item.answers.sort((a, b) => a.index - b.index)
            return item;
          }).sort((a, b) => {
            return a.index - b.index;
          }).map((item, index) => {
            if (item.image && item.image.url) {
              this.previews[index] = this.$store.state.appUrl + item.image.url;
            }
            return item;
          });
          this.tQuestions = questions;
        }).catch(e => {
          console.log(e)
        });
      },
      getCategories() {
        let url = '/categories';

        this.$axios.get(url).then(res => {
          this.categories = res.data;
        }).catch(e => {
          console.log(e)
        })
      },
      getQuestions() {
        let url = '/questions';

        this.$axios.get(url).then(res => {
          this.questions = res.data;
        }).catch(e => {
          console.log(e)
        })
      },
      getAnswers() {
        let url = '/answers';

        this.$axios.get(url).then(res => {
          this.answers = res.data;
        }).catch(e => {
          console.log(e)
        })
      },
    },
    watch: {

    },
    computed: {

    }
  }

</script>

