<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title class="headline error" style="color: #fff">
          Are you sure?
        </v-card-title>

        <v-card-text style="color: #000; padding-top: 24px">
          {{ this.text }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="submit"
          >
            Yes
          </v-btn>
          <v-btn
              text
              @click="close"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: 'This is a permanent and irreversible action.'
      }
    },
    data () {
      return {
        dialog: true,
      }
    },
    methods: {
      submit() {
        this.$emit('submitModal')
      },
      close() {
        this.$emit('closeModal')
      }
    }
  }
</script>